import { ReactNode } from "react";
import { Translate } from "components/common/Translate/Translate";
import { RouteNames } from "@/RouteNames";
import translatedRoutesSlugs from "translatedRouteSlugs";

export interface NavLinkProps {
    title: string | ReactNode;
    link: string;
    isEditor?: boolean;
    outlink?: boolean;
    isChild?: boolean;
    subItems?: NavChild[] | NavChild[][];
    urlMatcher?: (url: string, asPath: string, locale: string) => boolean;
}

export interface NavChild {
    title: string | ReactNode;
    link: string;
    outlink?: boolean;
    urlMatcher?: (url: string, asPath: string, locale: string) => boolean;
}

export const navLinks: NavLinkProps[] = [
    {
        title: <Translate keyName={"links:image-resizers"} />,
        link: "/",
        urlMatcher: (url) => {
            return url.includes("/resize/editor") || url.includes("/resize/download");
        },
        subItems: [
            {
                title: <Translate keyName="links:image-resizer" />,
                link: "/",
            },
            {
                title: <Translate keyName="links:png-resizer" />,
                link: RouteNames.pngResizer,
                urlMatcher: (url) => url === "resize-png",
            },
            {
                title: <Translate keyName="links:webp-resizer" />,
                link: RouteNames.webpResizer,
                urlMatcher: (url) => url === "/resize-webp/editor"
            },
            {
                title: <Translate keyName="links:jpg-resizer" />,
                link: RouteNames.jpgResizer,
                urlMatcher: (url) => url === "/resize-jpg/editor"
            },
        ],
    },
    {
        title: <Translate keyName={"image-cropper"} />,
        link: "/crop-image",
        urlMatcher: (url) => url === "/crop-image/editor",
    },
    {
        title: <Translate keyName={"links:image-compressors"} />,
        link: "/",
        subItems: [
            {
                title: <Translate keyName="image-compressor" />,
                link: RouteNames.imageCompressor,
                urlMatcher: (url) => url.includes("image-compressor"),
            },
            {
                title: <Translate keyName="jpeg-compressor" />,
                link: RouteNames.jpegCompressor,
                urlMatcher: (url) => url.includes("compress-jpeg"),
            },
            {
                title: <Translate keyName="png-compressor" />,
                link: RouteNames.pngCompressor,
                urlMatcher: (url) => url.includes("png-compressor"),
            },
            {
                title: <Translate keyName="gif-compressor" />,
                link: RouteNames.gifCompressor,
                urlMatcher: (url) => url.includes("gif-compressor"),
            },
        ],
        urlMatcher: (url) => url.includes("compressor"),
    },
    {
        title: <Translate keyName={"image-converters"} />,
        link: "/asdf",
        urlMatcher: (_, asPath) => asPath.includes("-converter") || asPath.includes("-to-"),
        subItems: [
            [
                {
                    title: <Translate keyName={"image-converter"} />,
                    link: "/image-converter",
                    urlMatcher: (url) => url === "/image-converter/editor",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "SVG" }} />,
                    link: "/svg-converter",
                    urlMatcher: (_, asPath) => asPath === "/svg-converter",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "PNG" }} />,
                    link: "/png-converter",
                    urlMatcher: (_, asPath) => asPath === "/png-converter",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "JPG" }} />,
                    link: "/jpg-converter",
                    urlMatcher: (_, asPath) => asPath === "/jpg-converter",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "GIF" }} />,
                    link: "/gif-converter",
                    urlMatcher: (_, asPath) => asPath === "/gif-converter",
                },
            ],
            [
                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "HEIC", target: "JPG" }} />,
                    link: "/heic-to-jpg",
                    urlMatcher: (_, asPath) => asPath === "/heic-to-jpg",
                },

                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "WebP", target: "PNG" }} />,
                    link: "/webp-to-png",
                    urlMatcher: (_, asPath) => asPath === "/webp-to-png",
                },
                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "WebP", target: "JPG" }} />,
                    link: "/webp-to-jpg",
                    urlMatcher: (_, asPath) => asPath === "/webp-to-jpg",
                },
                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "PNG", target: "JPG" }} />,
                    link: "/png-to-jpg",
                    urlMatcher: (_, asPath) => asPath === "/png-to-jpg",
                },
                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "PNG", target: "SVG" }} />,
                    link: "/png-to-svg",
                    urlMatcher: (_, asPath) => asPath === "/png-to-svg",
                },
            ],
        ],
    },
    {
        title: <Translate keyName={"bulk-resize"} />,
        link: "/bulk-resize",
        urlMatcher: (url) => url === "/bulk-resize/editor",
    },
    {
        title: (
            <span className="capitalize">
                <Translate keyName={"more"} />
            </span>
        ),
        link: "/",
        subItems: [
            {
                title: <Translate keyName="meme-generator" />,
                link: "/meme-generator",
            },
            {
                title: <Translate keyName="color-picker" />,
                link: "/color-picker",
            },
            {
                title: <Translate keyName="rotate-image" />,
                link: "/rotate-image",
            },
            {
                title: <Translate keyName="flip-image" />,
                link: "/flip-image",
            },
            {
                title: <Translate keyName="image-enlarger" />,
                link: "/image-enlarger",
                urlMatcher: (url) => url === "/image-enlarger/editor",
            },
        ],
    },
];
